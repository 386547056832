.validate-email{
    background-color: #ffffff;
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
   
    h1{
        letter-spacing: 1px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    input{
        width: 100%;
        font-size: 20px;
        padding: 10px;
        margin-bottom: 10px;
         letter-spacing: 1px;
    }
    .validate-btn, .close-btn{
        background-color: #12B76A;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        color: #ffffff;
        font-weight: 500;
        letter-spacing: 1px;


    }
    .close-btn{
        margin-left: 10px;
         background-color: #EF574C;

    }
}