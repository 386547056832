.jobs{
    width: 100%;
    .head{
        background-color: #2F80ED;
        color: #fff;
        letter-spacing: 1px;
        padding: 10px;
        text-align: center;
        margin-bottom: 10px;
    }
    .application-submission{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .lottie{
            width: 200px;
        }
        .success{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            h3{
                background-color: #E3FFED;
                color:#12B76A;
                padding: 10px 20px;
                font-size: 18px;
                text-align: center;
                letter-spacing: 1px;
                border-radius: 5px;


            }
            button{
                border: none;
                background-color: #2F80ED;
                color: #fff;
                padding: 8px 10px;
                font-size: 14px;
                margin-top: 20px;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        .failed{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: center;
            h3{
                background-color: #FDE6E4;
                color:#EF574C;
                padding: 10px 20px;
                font-size: 18px;
                text-align: center;
                letter-spacing: 1px;
                border-radius: 5px;


            }
            button{
                border: none;
                background-color: #2F80ED;
                color: #fff;
                padding: 8px 10px;
                font-size: 14px;
                margin-top: 20px;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
}

// /* Page Styles */
// .job-portal {
// 	display: flex;
// 	flex-direction: row;
// 	align-items: start;
// 	justify-content: start;
// 	padding-left: 50px;
// 	width: 100%;
// 	height: 100vh;
// }

// .job-portal .column-1 {
// 	padding-bottom: 30px;
// 	width: 67%;
// 	height: 100vh;
// 	overflow: scroll;
// 	padding-right: 60px;
// }

// .job-portal .column-1::-webkit-scrollbar {
// 	display: none;
// }

// .job-portal .column-2 {
// 	background: #f4f6f9;
// 	width: 33%;
// 	padding: 0px 25px;
// 	padding-bottom: 25px;
// 	height: 100vh;
// 	overflow: scroll;
// }

// .job-portal .column-2::-webkit-scrollbar {
// 	display: none;
// }

// /* Student Profile Overview Begin */
// .job-portal .column-2 .student-profile-overview {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: start;
// 	justify-content: start;
// 	background: #ffffff;
// 	border-radius: 12px;
// 	padding: 10px 25px;
// 	margin: 3rem 0 1.5rem;
// }

// .job-portal .column-2 .student-profile-overview > div {
// 	width: 100%;
// }

// .job-portal .column-2 .student-profile-overview .profile {
// 	display: flex;
// 	align-items: center;
// 	margin: 1rem 0;
// }

// .job-portal .column-2 .student-profile-overview .profile img {
// 	width: 54px;
// 	height: 54px;
// 	border-radius: 50%;
// 	object-fit: cover;
// }

// .job-portal .column-2 .student-profile-overview .profile-text {
// 	margin-left: 0.3rem;
// }

// /* name */
// .job-portal .column-2 .student-profile-overview .profile-text div:nth-child(1) {
// 	font-weight: 700;
// 	font-size: 1.175rem;
// }

// /* email */
// .job-portal .column-2 .student-profile-overview .profile-text div:nth-child(2) {
// 	font-size: 0.85rem;
// 	font-weight: 500;
// 	color: rgb(75, 75, 75);
// }

// .job-portal .column-2 .student-profile-overview .mock-status {
// 	display: grid;
// 	/* grid-template-columns: 1fr 1fr; */
// 	column-gap: 1rem;
// 	margin: 1.25rem 0 1.5rem;
// }

// .job-portal .column-2 .student-profile-overview .mock-status > div {
// 	display: flex;
// 	align-items: center;
// }

// .job-portal .column-2 .student-profile-overview .mock-status img {
// 	width: 45px;
// 	height: 45px;
// }

// .job-portal .column-2 .student-profile-overview .mock-status .mock-data {
// 	margin-left: 0.75rem;
// }

// .job-portal .mock-status .mock-data div:nth-child(1) {
// 	font-size: 1.1rem;
// 	font-weight: 700;
// }

// .job-portal .mock-status .mock-data div:nth-child(2) {
// 	font-size: 0.8rem;
// }

// .job-portal .column-2 .divider {
// 	background: #f0f0f0;
// 	width: 100%;
// 	height: 1px;
// }

// .job-portal .column-2 .resume-status {
// 	display: flex;
// 	align-items: center;
// 	margin: 1.25rem 0 0.5rem;
// }
// .job-portal .column-2 .resume-status img {
// 	height: 50px;
// 	width: 50px;
// }

// .job-portal .column-2 .resume-status img:nth-of-type(2) {
// 	height: 45px;
// 	width: 45px;
// }

// .job-portal .column-2 .resume-status div {
// 	flex-grow: 1;
// 	font-size: 1.2rem;
// 	font-weight: 600;
// 	padding-left: 1rem;
// }
// /* Student Profile Overview End */

// /* Student Placement Overview Begin */
// .job-portal .column-2 .student-placement-overview {
// 	background: #ffffff;
// 	border-radius: 12px;
// 	padding: 10px 25px;
// 	padding-bottom: 0.75rem;
// }

// .job-portal .column-2 .student-placement-overview h3 {
// 	font-weight: 700;
// 	margin: 1rem 0 1.5rem;
// }

// .job-portal .column-2 .student-placement-overview .placement-stages > button {
// 	background: transparent;
// 	border: 0;
// 	outline: 0;
// 	font-size: 1rem;
// 	cursor: pointer;
// 	display: flex;
// 	align-items: center;
// 	margin: 0.25rem 0 1rem;
// 	width: 100%;
// 	text-align: left;
// }

// .job-portal .column-2 .student-placement-overview .placement-stages img {
// 	height: 1.5rem;
// 	width: 1.5rem;
// }

// .job-portal .column-2 .student-placement-overview .placement-stages .title {
// 	flex-grow: 1;
// 	font-weight: 600;
// 	padding-left: 0.8rem;
// }

// .job-portal .column-2 .student-placement-overview .placement-stages .value {
// 	padding-right: 0.5rem;
// 	font-weight: 600;
// 	font-size: 1.1rem;
// }

// .job-portal .column-2 .student-placement-overview .placement-stages svg {
// 	height: 1.2rem;
// 	width: 1.2rem;
// }
// /* Student Placement Overview End */

// /* Tabs Begin */
// .job-portal .column-1 .tabs {
// 	margin-bottom: 1.5rem;
// 	border-bottom: 1.2px solid #e4e7ec;
// }

// .job-portal .column-1 .tabs button {
// 	background: transparent;
// 	border: 0;
// 	outline: 0;
// 	font-size: 1rem;
// 	cursor: pointer;
// 	font-weight: 600;
// 	color: #667085;
// 	border-bottom: 2px solid transparent;
// 	width: 200px;
// 	padding: 30px 0 20px;
// 	transition: all 0.2s ease;
// }

// .job-portal .column-1 .tabs button:hover {
// 	opacity: 0.75;
// }

// .job-portal .column-1 .tabs button.active {
// 	color: #003a8c;
// 	border-bottom: 3px solid #003a8c;
// }

// /* Tabs End */

// /* Job Cards Begin */
// .job-portal .column-1 .tab-content {
// 	display: grid;
// 	grid-template-columns: repeat(2, minmax(0, 1fr));
// 	column-gap: 1.5rem;
// 	row-gap: 1.5rem;
// 	text-transform: capitalize;
// }

// .job-portal .column-1 .tab-content .job-card {
// 	border: 1.5px solid rgba(131, 147, 169, 0.25);
// 	border-radius: 12px;
// 	padding: 22px;
// }

// .job-portal .job-card .header-area {
// 	display: grid;
// 	grid-template-columns: 1.9fr 1fr;
// 	align-items: center;
// }

// .job-portal .job-card .footer-area {
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	align-items: center;
// }

// .job-portal .job-card .header-area {
// 	margin-bottom: 1.3rem;
// }

// .job-portal .job-card .header-area .deadline img {
// 	height: 22px;
// 	width: 22px;
// 	margin-right: 0.35rem;
// }

// .job-portal .job-card .header-area .deadline {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: end;
// 	gap: 0.2rem;
// }

// .job-portal .job-card .header-area .deadline p {
// 	display: flex;
// 	align-items: center;
// 	font-weight: 600;
// }

// .job-portal .job-card .header-area .deadline p:nth-of-type(1) {
// 	font-size: 0.8rem;
// 	color: #ff940a;
// }

// .job-portal .job-card .header-area .deadline p:nth-of-type(2) {
// 	font-size: 0.85rem;
// 	color: #012555;
// }

// .job-portal .job-card .company-overview h3 {
// 	font-weight: 700;
// 	margin-bottom: 0.75rem;
// 	text-transform: capitalize;
// 	font-size: 1.25rem;
// }

// .job-portal .job-card .company-overview div {
// 	display: flex;
// 	align-items: center;
// 	gap: 12px;
// 	font-size: 0.85rem;
// 	font-weight: 500;
// }

// .job-portal .job-card .company-overview div img {
// 	height: 24px;
// 	width: 24px;
// 	margin-right: 0.35rem;
// }

// .job-portal .job-card .company-overview div p {
// 	display: flex;
// 	align-items: center;
// }

// .job-portal .job-card .company-overview div p:nth-of-type(1) {
// 	color: #003a8c;
// }

// .job-portal .job-card .company-overview div p:nth-of-type(2) {
// 	color: #12b76a;
// }

// .job-portal .job-card .body-area div {
// 	margin-bottom: 1rem;
// }

// .job-portal .job-card .body-area h4 {
// 	font-size: 13px;
// 	margin: 0 0 0.4rem;
// 	color: #8393a9;
// 	font-weight: 500;
// 	letter-spacing: 0.3px;
// }

// .job-portal .job-card .body-area p {
// 	font-size: 0.9rem;
// 	font-weight: 500;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// 	overflow: hidden;
// }

// .job-portal .job-card .skills p {
// 	display: flex;
// 	gap: 5px;
// 	flex-wrap: wrap;
// }

// .job-portal .job-card .skills span {
// 	font-size: 0.75rem;
// 	padding: 4px 12px;
// 	background: rgba(235, 243, 254, 0.5);
// 	border: 0.731884px solid rgba(131, 147, 169, 0.25);
// 	border-radius: 4.3913px;
// 	color: #012555;
// 	font-weight: 500;
// }

// /* .job-portal .job-card .process {
// } */

// .job-portal .job-card .footer-area {
// 	column-gap: 1rem;
// 	margin-top: 1.3rem;
// }
// .job-portal .job-card .footer-area button {
// 	background: transparent;
// 	border: 0;
// 	outline: 0;
// 	font-size: 1rem;
// 	cursor: pointer;
// 	padding: 0.7rem 0;
// 	font-size: 0.8rem;
// 	border-radius: 4.3913px;
// 	font-weight: 500;
// }

// .job-portal .job-card .footer-area .apply {
// 	background-color: #003a8c;
// 	color: white;
// }


// .job-portal .job-card .footer-area .apply:disabled {
// 	cursor: default;
// 	opacity: 0.65;
// }

// .job-portal .job-card .footer-area .details {
// 	border: 0.731884px solid rgba(131, 147, 169, 0.25);
// }
// /* Job Cards End */



// /* off campus card*/


// .job-portal .column-1 .tab-content .off-campus-card {
// 	border: 1.5px solid rgba(131, 147, 169, 0.25);
// 	border-radius: 12px;
// 	padding: 22px;
// }

// .job-portal .off-campus-card .header-area {
// 	display: grid;
// 	grid-template-columns: 1.9fr 1fr;
// 	align-items: center;
// }

// .job-portal .off-campus-card .footer-area {
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	align-items: center;
// }

// .job-portal .off-campus-card .header-area {
// 	margin-bottom: 1.3rem;
// }

// .job-portal .off-campus-card .header-area .deadline img {
// 	height: 22px;
// 	width: 22px;
// 	margin-right: 0.35rem;
// }

// .job-portal .off-campus-card .header-area .deadline {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: end;
// 	gap: 0.2rem;
// }

// .job-portal .off-campus-card .header-area .deadline p {
// 	display: flex;
// 	align-items: center;
// 	font-weight: 600;
// }

// .job-portal .off-campus-card .header-area .deadline p:nth-of-type(1) {
// 	font-size: 0.8rem;
// 	color: #ff940a;
// }

// .job-portal .off-campus-card .header-area .deadline p:nth-of-type(2) {
// 	font-size: 0.85rem;
// 	color: #012555;
// }

// .job-portal .off-campus-card .company-overview h3 {
// 	font-weight: 700;
// 	margin-bottom: 0.75rem;
// 	text-transform: capitalize;
// 	font-size: 1.25rem;
// }

// .job-portal .off-campus-card .company-overview div {
// 	display: flex;
// 	align-items: center;
// 	gap: 12px;
// 	font-size: 0.85rem;
// 	font-weight: 500;
// }

// .job-portal .off-campus-card .company-overview div img {
// 	height: 24px;
// 	width: 24px;
// 	margin-right: 0.35rem;
// }

// .job-portal .off-campus-card .company-overview div p {
// 	display: flex;
// 	align-items: center;
// }

// .job-portal .off-campus-card .company-overview div p:nth-of-type(1) {
// 	color: #003a8c;
// }

// .job-portal .off-campus-card .company-overview div p:nth-of-type(2) {
// 	color: #12b76a;
// }

// .job-portal .off-campus-card .body-area div {
// 	margin-bottom: 1rem;
// }

// .job-portal .off-campus-card .body-area h4 {
// 	font-size: 13px;
// 	margin: 0 0 0.4rem;
// 	color: #8393a9;
// 	font-weight: 500;
// 	letter-spacing: 0.3px;
// }

// .job-portal .off-campus-card .body-area p {
// 	font-size: 0.9rem;
// 	font-weight: 500;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// 	overflow: hidden;
// }

// .job-portal .off-campus-card .skills p {
// 	display: flex;
// 	gap: 5px;
// 	flex-wrap: wrap;
// }

// .job-portal .off-campus-card .skills span {
// 	font-size: 0.75rem;
// 	padding: 4px 12px;
// 	background: rgba(235, 243, 254, 0.5);
// 	border: 0.731884px solid rgba(131, 147, 169, 0.25);
// 	border-radius: 4.3913px;
// 	color: #012555;
// 	font-weight: 500;
// }

// /* .job-portal .off-campus-card .process {
// } */

// .job-portal .off-campus-card .footer-area {
// 	column-gap: 1rem;
// 	margin-top: 1.3rem;
// }
// .job-portal .off-campus-card .footer-area button {
// 	background: transparent;
// 	border: 0;
// 	outline: 0;
// 	font-size: 1rem;
// 	cursor: pointer;
// 	padding: 0.7rem 0;
// 	font-size: 0.8rem;
// 	border-radius: 4.3913px;
// 	font-weight: 500;
// }

// .job-portal .off-campus-card .footer-area .apply {
// 	background-color: #003a8c;
// 	color: white;
// }


// .job-portal .off-campus-card .footer-area .apply:disabled {
// 	cursor: default;
// 	opacity: 0.65;
// }

// .job-portal .off-campus-card .footer-area .details {
// 	border: 0.731884px solid rgba(131, 147, 169, 0.25);
// }
// /* Job Cards End */

// /* off campus card end*/


// /* Applcation Cards Begin */
// .job-portal .column-1 .tab-content .application-card {
// 	border: 1.5px solid rgba(131, 147, 169, 0.25);
// 	border-radius: 12px;
// 	padding: 22px;
// }

// .job-portal .application-card .header-area {
// 	margin-bottom: 1.3rem;
// }

// .job-portal .application-card .company-overview h3 {
// 	font-weight: 700;
// 	margin-bottom: 0.75rem;
// }

// .job-portal .application-card .company-overview p {
// 	background: #dcebff;
// 	border-radius: 30px;
// 	width: max-content;
// 	padding: 0.2rem 1.2rem;
// 	font-size: 0.8rem;
// 	font-weight: 500;
// }

// .job-portal .application-card .body-area {
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	align-items: center;
// 	column-gap: 1rem;
// 	row-gap: 1.1rem;
// }

// .job-portal .application-card .body-area div {
// 	display: flex;
// 	align-items: center;
// 	gap: 8px;
// 	font-size: 0.9rem;
// 	font-weight: 600;
// }

// .job-portal .application-card .body-area div:nth-of-type(1) {
// 	color: #ff930a;
// }

// .job-portal .application-card .body-area div:nth-of-type(2) {
// 	color: #2f80ed;
// }

// .job-portal .application-card .body-area div:nth-of-type(3) {
// 	color: #12b76a;
// }

// .job-portal .application-card .body-area div img {
// 	height: 24px;
// 	width: 24px;
// }

// .job-portal .application-card .body-area button {
// 	background: transparent;
// 	border: 0;
// 	outline: 0;
// 	font-size: 1rem;
// 	cursor: pointer;
// 	border: 0.731884px solid #003a8c;
// 	border-radius: 4.3913px;
// 	padding: 0.45rem 0;
// 	font-size: 0.8rem;
// 	font-weight: 600;
// 	color: #003a8c;
// }
// /* Application Cards End */

// /* Drives Popup Start*/

// .job-portal-drives-popup-inner {
// 	padding: 1.75rem;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// }

// .job-portal-drives-popup-inner .popup-header {
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	width: 525px;
// 	margin-bottom: 1.75rem;
// }

// .job-portal-drives-popup-inner .popup-header svg {
// 	cursor: pointer;
// 	height: 1.5rem;
// 	width: 1.5rem;
// 	transition: all 0.2s ease;
// }

// .job-portal-drives-popup-inner .popup-header svg:hover {
// 	opacity: 0.75;
// }

// .job-portal-drives-popup-inner .popup-header h2 {
// 	font-size: 1.75rem;
// 	font-weight: 700;
// 	display: flex;
// 	align-items: center;
// }

// .job-portal-drives-popup-inner .popup-header h2 img {
// 	height: 2.5rem;
// 	width: 2.5rem;
// 	margin-right: 0.5rem;
// }

// .job-portal-drives-popup-inner .popup-body {
// 	max-height: 275px;
// 	overflow-y: scroll;
// }

// .job-portal-drives-popup-inner table {
// 	width: 475px;
// 	font-size: 0.9rem;
// }

// .job-portal-drives-popup-inner th,
// .job-portal-drives-popup-inner td {
// 	/* border: 1px solid #343a40; */
// 	padding: 16px 24px;
// 	text-align: left;
// }

// .job-portal-drives-popup-inner thead th {
// 	background: #f3f8ff;
// 	color: #003a8c;
// }

// .job-portal-drives-popup-inner tbody tr {
// 	border-bottom: 2.29636px solid #f3f8ff;
// 	font-weight: 500;
// }

// /* Drives Popup End*/

// /* Job Details Popup Start */
// .job-portal-job-details-popup-inner {
// 	padding: 1.5rem;
// }

// .job-portal-job-details-popup-inner .popup-header {
// 	display: flex;
// 	justify-content: space-between;
// 	margin-bottom: 1.4rem;
// 	text-transform: capitalize;
// }

// .job-portal-job-details-popup-inner .popup-header > div h2 {
// 	font-weight: 700;
// 	margin-bottom: 1rem;
// }

// .job-portal-job-details-popup-inner .popup-header svg {
// 	cursor: pointer;
// 	height: 1.5rem;
// 	width: 1.5rem;
// 	transition: all 0.2s ease;
// }

// .job-portal-job-details-popup-inner .popup-header svg:hover {
// 	opacity: 0.75;
// }

// .job-portal-job-details-popup-inner .popup-header > div p {
// 	font-size: 1rem;
// 	font-weight: 600;
// 	display: flex;
// 	align-items: center;
// 	gap: 1rem;
// }

// .job-portal-job-details-popup-inner .popup-header > div p span {
// 	display: flex;
// 	align-items: center;
// }

// .job-portal-job-details-popup-inner .popup-header > div p span img {
// 	margin-right: 0.5rem;
// }

// .job-portal-job-details-popup-inner .popup-header > div p span:nth-of-type(1) {
// 	color: #003a8c;
// }

// .job-portal-job-details-popup-inner .popup-header > div p span:nth-of-type(2) {
// 	color: #12b76a;
// }

// .job-portal-job-details-popup-inner .popup-body .meta p {
// 	margin: 0 0 0.5rem;
// 	display: flex;
// 	align-items: flex-start;
// 	gap: 0.5rem;
// 	font-weight: 500;
// }

// .job-portal-job-details-popup-inner .popup-body .meta p span {
// 	color: #8393a9;
// 	text-transform: uppercase;
// 	font-weight: 600;
// 	font-size: 0.9rem;
// 	margin-top: 1px;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box {
// 	margin: 1.25rem 0 0;
// 	border-radius: 5px;
// 	padding: 1rem;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box.isNotEligible {
// 	background: rgba(228, 87, 71, 0.2);
// 	border: 1px solid #e45747;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box.isEligible {
// 	background: #c6dcc3;
// 	border: 1px solid #6cb851;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box.info {
// 	background: #ebf3fe;
// 	border: 1px solid #2f80ed;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box h3 {
// 	font-weight: 600;
// 	font-size: 0.9rem;
// 	text-transform: uppercase;
// 	margin-bottom: 0.35rem;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box.isNotEligible h3 {
// 	color: #d80027;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box.isEligible h3 {
// 	color: #349113;
// 	margin-bottom: 0;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box.info h3 {
// 	color: #2f80ed;
// }

// .job-portal-job-details-popup-inner .popup-body .info-box p {
// 	font-size: 0.9rem;
// 	color: #2b2f34;
// 	font-weight: 500;
// }

// .job-portal-job-details-popup-inner .popup-body .jd h3 {
// 	color: #8393a9;
// 	text-transform: uppercase;
// 	font-weight: 600;
// 	font-size: 0.95rem;
// 	margin: 1.15rem 0 0.5rem;
// }

// .job-portal-job-details-popup-inner .popup-body .jd .rich-text {
// 	font-size: 0.9rem;
// 	font-weight: 400;
// 	line-height: 1.4;
// 	letter-spacing: 0.3px;
// }

// .job-portal-job-details-popup-inner .popup-footer {
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	column-gap: 2rem;
// 	margin-top: 1rem;
// }

// .job-portal-job-details-popup-inner .popup-footer button {
// 	background: transparent;
// 	border: 0;
// 	outline: 0;
// 	font-size: 1rem;
// 	cursor: pointer;
// 	background: #003a8c;
// 	color: white;
// 	border-radius: 5px;
// 	padding: 0.75rem 0;
// 	font-size: 0.9rem;
// 	font-weight: 500;
// }

// .job-portal-job-details-popup-inner .popup-footer button:disabled {
// 	cursor: default;
// 	opacity: 0.65;
// }

// .job-portal-job-details-popup-inner .popup-footer .deadline {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	color: #012555;
// 	font-weight: 600;
// 	font-size: 0.9rem;
// }

// .job-portal-job-details-popup-inner .popup-footer .deadline img {
// 	margin-right: 0.35rem;
// 	width: 20px;
// 	height: 20px;
// }

// .job-portal-job-details-popup-inner .popup-footer .deadline span {
// 	color: #ff940a;
// 	margin-right: 0.75rem;
// 	font-size: 1rem;
// }

// /* Job Details Popup End */

// /* Custom Tooltip Begin */
// .custom-tooltip {
// 	position: relative;
// }

// .custom-tooltip:hover:after {
// 	background: rgba(0, 0, 0, 0.7);
// 	border-radius: 0.5em;
// 	bottom: 1.85rem;
// 	color: #fff;
// 	content: attr(data-tooltip);
// 	display: block;
// 	left: 1em;
// 	padding: 0.3em 1em;
// 	position: absolute;
// 	text-shadow: 0 1px 0 #000;
// 	z-index: 98;
// 	font-size: 0.85rem;
// }

// .custom-tooltip:hover:before {
// 	border: solid;
// 	border-color: rgba(0, 0, 0, 0.7) transparent;
// 	border-width: 0.4em 0.4em 0 0.4em;
// 	bottom: 1.5rem;
// 	content: '';
// 	display: block;
// 	left: 2em;
// 	position: absolute;
// 	z-index: 99;
// }
/* Custom Tooltip End*/

/* Custom Questions Popup Begin */

.job-portal-custom-questions-popup-inner {
	padding: 10px;
    height: 100%;
    @media (min-width:500px) {
            width: 70%;
            margin: auto;
        }
}

.job-portal-custom-questions-popup-inner .popup-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 2rem;
}

.job-portal-custom-questions-popup-inner .popup-header svg {
	cursor: pointer;
	height: 1.5rem;
	width: 1.5rem;
	transition: all 0.2s ease;
}

.job-portal-custom-questions-popup-inner .popup-header svg:hover {
	opacity: 0.75;
}

.job-portal-custom-questions-popup-inner .popup-header h3 {
	font-size: 1.75rem;
	font-weight: 700;
	margin-bottom: 0.5rem;
	text-transform: capitalize;
}

.job-portal-custom-questions-popup-inner .popup-header p {
	font-size: 1rem;
	color: #393c42;
}

.job-portal-custom-questions-popup-inner .confirmation-buttons {
	display: flex;
	gap: 1rem;
}

.job-portal-custom-questions-popup-inner .confirmation-buttons .apply-now,
.job-portal-custom-questions-popup-inner .confirmation-buttons .cancel {
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 1rem;
	cursor: pointer;
	padding: 0.65rem 3rem;
	background: #003a8c;
	color: white;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.75px;
	text-transform: capitalize;
	border: 10px;
}

.job-portal-custom-questions-popup-inner .confirmation-buttons .cancel {
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 1rem;
	cursor: pointer;
	background: white;
	color: #003a8c;
	border: 1px solid #003a8c;
}

.job-portal-custom-questions-popup-inner .popup-body .custom-question {
	margin-bottom: 1.5rem;
}

.job-portal-custom-questions-popup-inner .popup-body .custom-question > p {
	font-size: 0.95rem;
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.job-portal-custom-questions-popup-inner .popup-body .text-field div {
	width: 100%;
}

.job-portal-custom-questions-popup-inner .popup-body .radio-field *,
.job-portal-custom-questions-popup-inner .popup-body .checkbox-field * {
	font-size: 1rem;
}

.job-portal-custom-questions-popup-inner .popup-body .text-field div {
	width: 100%;
}

.job-portal-custom-questions-popup-inner .popup-body .custom-questions-submit {
	padding: 0.75rem 5rem;
	background: #003a8c;
	color: white;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.75px;
	text-transform: capitalize;
	border: 10px;
}

.job-portal-custom-questions-popup-inner .popup-body .popup-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.job-portal-custom-questions-popup-inner .popup-body .popup-footer span {
	color: red;
}

/* Custom Questions Popup End */

/* Profile Popup Start */

.job-portal-profile-popup {
	padding: 1.5rem;
}

.job-portal-profile-popup .popup-header {
	margin-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.job-portal-profile-popup .popup-header button {
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 1rem;
	cursor: pointer;
	color: #003a8c;
	font-weight: 600;
	display: flex;
	align-items: flex-start;
}

.job-portal-profile-popup .popup-header button svg {
	font-size: 1.5rem;
	margin-left: 0.75rem;
}

.job-portal-profile-popup .popup-header h2 {
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 0.5rem;
}

.job-portal-profile-popup .popup-body .form-fields-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 1.2rem;
	column-gap: 1rem;
}

.job-portal-profile-popup .popup-body .form-fields-container *:focus {
	outline: 0 !important;
}

.job-portal-profile-popup .MuiFormLabel-asterisk {
	color: red;
}

.job-portal-profile-popup .profile-submit {
	padding: 0.75rem 5rem;
	background: #003a8c;
	color: white;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.75px;
	text-transform: capitalize;
	border: 10px;
	margin-top: 1.25rem;
}

.job-portal-profile-popup .profile-submit:hover {
	opacity: 0.8;
	background: #003a8c;
}

.job-portal-profile-popup-parent ::-webkit-scrollbar {
	width: 7px;
}

.MuiPopover-paper.MuiPaper-elevation.MuiPaper-rounded::-webkit-scrollbar {
	width: 7px;
}

/* Profile Popup End */

/* ScrollBar For Popups Begin */
.thin-scrollbar ::-webkit-scrollbar {
	width: 7px;
}
/* ScrollBar For Popups End */
.mock-data-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.mock-data-cont .mock-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.mock-data {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.mock-status-btn {
	border: none;
	background: transparent;
}
.mock-data-p {
	/* margin-left: 15%; */
	display:flex;
	align-items: center;
	justify-content: flex-end;
}
.mock-head div{
	margin-left:20px;
	font-weight: 600;
	font-size: 18px;
}
