.portal-overlay{
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.6);
  display: flex;
}

.App{
  position: relative;
  padding-bottom: 100px;
  .banner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(131, 131, 131);
    img{
      margin-right: 10px;
    }
  }
  footer{
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #F0F0F0;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    p{
      color: #505862;
    }
  }
}



.loader{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}